import React from 'react'
import Link from 'gatsby-link'
import './style.css'

const About = () => (
  <div className="about-page">
    <div className="article-back ab-scanning-hits"><Link to='/table-of-contents'>&larr; Home</Link></div>
    <div className="about-content-box">
        <p>This site was started and built during a graduate level research class at the University of Houston as part of the Master's Degree Foresight program. All research, writing, interviews, content, and coding were done by Tom Rau. Additional art and visual design was provided by Katie Frohbose.</p>
        <p>If your interested in working together on a futures project, a software project, or any other creative collaborative projects don't hesitate to drop me an email at <a href="mailto:tomrau@gmail.com">tomrau@gmail.com</a>. I'm always looking for interesting and challenging projects.</p>
        <ul>
            <li><a target="_blank" href="https://www.linkedin.com/in/tom-rau/">LinkedIn</a></li>
            <li><a target="_blank" href="https://twitter.com/HiFiLoRau">Twitter</a></li>
        </ul>

    </div>
  </div>
)

export default About
